var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ValidationProvider", {
    staticStyle: { width: "100%" },
    attrs: { rules: _vm.rules },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function ({ errors }) {
            return [
              _vm._t("default"),
              _c("ValidationError", {
                staticClass: "ma-0",
                attrs: { error: errors[0] },
              }),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }